const aerialTabs = () => {
  const tabsContainers = document.querySelectorAll(
    '[data-gallery-popup-window]'
  )

  tabsContainers.forEach(tabsContainer => {
    const tabsElements = [
      ...tabsContainer.querySelectorAll('[data-popup-content-button]'),
    ]
    const tabsContents = [
      ...tabsContainer.querySelectorAll('[data-popup-content]'),
    ]

    tabsElements.forEach(tabsElement => {
      tabsElement.addEventListener('click', () => {
        tabsElements.forEach(tab => tab.parentNode.classList.remove('active'))
        tabsElement.parentNode.classList.add('active')
        tabsContents.forEach(tabsContent =>
          tabsContent.classList.remove('is-active')
        )

        const activeTab = tabsContents.find(
          tabsContent =>
            tabsContent.dataset.popupContent ===
            tabsElement.dataset.popupContentButton
        )

        activeTab.classList.add('is-active')
      })
    })

    if (tabsElements[0]) {
      tabsElements[0].click()
    }
  })
}

export default aerialTabs
