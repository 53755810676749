const loadImages = container => {
  container.querySelectorAll('[data-lazy]').forEach(img => {
    img.src = img.dataset.src
    img.srcset = img.dataset.src2x || img.dataset.src

    img.addEventListener('load', () => {
      img.parentNode.classList.add('load')
    })
  })
}

export default loadImages
