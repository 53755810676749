export default container => {
  const sections = container.querySelectorAll('[data-scroll-section]')
  const hashLinksContainers = container.querySelectorAll(
    '[data-hash-links-container]'
  )

  hashLinksContainers.forEach(hashLinksContainer => {
    hashLinksContainer.addEventListener('click', e => {
      e.preventDefault()
      sections.forEach(section => {
        if (section.dataset.scrollSection === e.target.dataset.scrollToItem) {
          const topPos =
            section.getBoundingClientRect().top + window.pageYOffset

          window.scroll({
            top: topPos,
            behavior: 'smooth',
          })
        }
      })
    })
  })
}
