import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Rellax from 'rellax'
import { mobile } from './_constants'

gsap.registerPlugin(ScrollTrigger)

const changeStaticCover = () => {
  if (mobile) return

  let rellaxEl
  const staticCover = document.querySelector('[data-static-cover]')
  const staticMap = document.querySelector('[data-static-wrapper]')
  const switchSection = document.querySelector('[data-about-section]')

  if (!staticCover || !staticMap) return

  const switchBG = (direction, cover, map) => {
    if (direction === 1) {
      cover.style.position = 'absolute'
      map.style.position = 'fixed'
    } else if (direction === -1) {
      cover.style.position = 'fixed'
      map.style.position = 'absolute'
    }
  }

  const rellaxObserver = (direction, img) => {
    if (direction === 1) {
      rellaxEl = new Rellax(img, {
        center: true,
        speed: 2,
      })
    }
    if (direction === -1) {
      rellaxEl.destroy()
    }
  }

  gsap.to(staticCover, {
    scrollTrigger: {
      trigger: switchSection,
      start: 'top 0',
      end: 'top 100%',
      onEnter: e => switchBG(e.direction, staticCover, staticMap),
      onEnterBack: e => switchBG(e.direction, staticCover, staticMap),
    },
  })
  gsap.to(staticCover, {
    scrollTrigger: {
      trigger: switchSection,
      start: 'bottom 0',
      end: 'bottom 100%',
      onEnter: e => switchBG(e.direction, staticCover, staticMap),
      onEnterBack: e => switchBG(e.direction, staticCover, staticMap),
    },
  })

  gsap.to(staticCover, {
    scrollTrigger: {
      trigger: switchSection,
      start: 'bottom 0',
      end: 'bottom 100%',
      onEnter: e => rellaxObserver(e.direction, staticMap),
      onEnterBack: e => rellaxObserver(e.direction, staticMap),
    },
  })
}

export default changeStaticCover
