const scrollbarWidth = () => {
  const div = document.createElement('div')

  div.style.overflowY = 'scroll'
  div.style.width = '50px'
  div.style.height = '50px'

  document.body.appendChild(div)
  const width = div.offsetWidth - div.clientWidth

  div.parentElement.removeChild(div)

  return width
}

export default scrollbarWidth
