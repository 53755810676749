import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const initTabs = (el, event) => {
  const tabsItems = el.querySelectorAll('[data-two-half-button]')
  const tabsImg = el.querySelectorAll('[data-main-img]')
  const tabsSecondImg = el.querySelectorAll('[data-second-img]')
  const tabsText = el.querySelectorAll('[data-main-text]')

  // make the first tab active when the page loaded
  if (tabsItems[0]) {
    const firstImgTab = [...tabsImg].find(
      item => item.dataset.mainImg === tabsItems[0].dataset.twoHalfButton
    )
    const firstSecondImgTab = [...tabsSecondImg].find(
      item => item.dataset.secondImg === tabsItems[0].dataset.twoHalfButton
    )
    const firstTextTab = [...tabsText].find(
      item => item.dataset.mainText === tabsItems[0].dataset.twoHalfButton
    )

    const makeTabActive = tab => {
      tab.classList.add('is-active')

      gsap.set(tab, { opacity: 0, y: '50%' })
      gsap.fromTo(
        tab,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.4,
          scrollTrigger: {
            trigger: tabsItems[0],
          },
        }
      )
      gsap.fromTo(
        tab,
        { y: '50%' },
        {
          y: 0,
          duration: 0.8,
          scrollTrigger: {
            trigger: tabsItems[0],
          },
        }
      )
    }

    tabsItems[0].classList.add('is-active')

    makeTabActive(firstImgTab)
    makeTabActive(firstSecondImgTab)
    makeTabActive(firstTextTab)
  }

  tabsItems.forEach(tabsItem => {
    tabsItem.addEventListener(event, () => {
      const prevActiveImgTab = el.querySelector('[data-main-img].is-active')
      const prevActiveSecondImgTab = el.querySelector(
        '[data-second-img].is-active'
      )
      const prevTabsText = el.querySelector('[data-main-text].is-active')

      const activeImgTab = [...tabsImg].find(
        item => item.dataset.mainImg === tabsItem.dataset.twoHalfButton
      )
      const activeSecondImgTab = [...tabsSecondImg].find(
        item => item.dataset.secondImg === tabsItem.dataset.twoHalfButton
      )
      const activeTabsText = [...tabsText].find(
        item => item.dataset.mainText === tabsItem.dataset.twoHalfButton
      )

      if (!prevActiveImgTab || !prevActiveSecondImgTab || !prevTabsText) return

      // the animation starts only when the user interacts with a new tab item
      const makeTabContentVisible = (
        activeTab,
        prevActiveTab,
        activeTabDataset,
        prevActiveTabDataset
      ) => {
        if (activeTab && prevActiveTabDataset !== activeTabDataset) {
          prevActiveTab.classList.remove('is-active')
          gsap.set(prevActiveTab, { opacity: 1, y: 0 })
          gsap.fromTo(
            prevActiveTab,
            { opacity: 1 },
            { opacity: 0, duration: 0.4 }
          )
          gsap.fromTo(prevActiveTab, { y: 0 }, { y: '4rem', duration: 0.8 })
          tabsItems.forEach(item => {
            item.style.pointerEvents = 'none'
            if (item.classList.contains('is-active')) {
              item.classList.remove('is-active')
            }
          })

          activeTab.classList.add('is-active')
          tabsItem.classList.add('is-active')
          const activeTabInnerElements = activeTab.querySelectorAll(
            '[data-two-half-tabs-element]'
          )
          gsap.set(activeTab, { opacity: 0, y: '4rem' })
          gsap.fromTo(
            activeTab,
            { opacity: 0 },
            {
              opacity: 1,
              duration: 0.8,
              delay: 0.5,
              onComplete: () => {
                tabsItems.forEach(item => {
                  item.style.pointerEvents = 'all'
                })
              },
            }
          )
          gsap.fromTo(
            activeTab,
            { y: '4rem' },
            { y: 0, duration: 0.8, delay: 0.4 }
          )
          gsap.set(activeTabInnerElements, { y: '4rem' })
          gsap.fromTo(
            activeTabInnerElements,
            { y: '4rem' },
            { y: 0, duration: 0.8, stagger: 0.1, delay: 0.4 }
          )
        }
      }

      makeTabContentVisible(
        activeImgTab,
        prevActiveImgTab,
        activeImgTab.dataset.mainImg,
        prevActiveImgTab.dataset.mainImg
      )
      makeTabContentVisible(
        activeSecondImgTab,
        prevActiveSecondImgTab,
        activeSecondImgTab.dataset.secondImg,
        prevActiveSecondImgTab.dataset.secondImg
      )
      makeTabContentVisible(
        activeTabsText,
        prevTabsText,
        activeTabsText.dataset.mainText,
        prevTabsText.dataset.mainText
      )
    })
  })
}

export default container => {
  const tabsContainers = container.querySelectorAll('[data-two-half-tabs]')

  tabsContainers.forEach(tabsContainer => {
    const event = tabsContainer.dataset.twoHalfTabs
      ? tabsContainer.dataset.twoHalfTabs
      : 'mouseover'
    initTabs(tabsContainer, event)
  })
}
