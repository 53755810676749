const changeImageOnHover = () => {
  const imagePreviewWrappers = document.querySelectorAll('[data-popup-content]')

  imagePreviewWrappers.forEach(imagePreviewWrapper => {
    const imagePreviews = imagePreviewWrapper.querySelectorAll(
      '[data-image-change]'
    )
    const imageContainers = [
      ...imagePreviewWrapper.querySelectorAll('[data-image-container]'),
    ]

    imagePreviews.forEach((imagePreview, i) => {
      if (i === 0) {
        imagePreview.classList.add('show')
      } else {
        imagePreview.classList.remove('show')
      }

      imagePreview.addEventListener('mouseover', e => {
        const activeImageContainer = imageContainers.find(
          img => img.dataset.imageContainer === e.target.dataset.imageChange
        )

        imagePreviews.forEach(img => img.classList.remove('show'))

        imagePreview.classList.add('show')

        imageContainers.forEach(imageContainer => {
          imageContainer.classList.remove('show')
          const video = imageContainer.querySelector('video')
          if (video) {
            video.pause()
          }
        })
        activeImageContainer.classList.add('show')
        const video = activeImageContainer.querySelector('video')
        if (video) {
          video.play()
        }
      })
    })
  })
}

export default changeImageOnHover
