import MicroModal from 'micromodal'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { header, page } from './_constants'
import scrollbarWidth from './scrollbar-width'
import loadImages from './load-images'
import initMarzipano from '../marzipano'

const imageContainers = [...document.querySelectorAll('[data-image-container]')]

const onShowModal = modal => {
  loadImages(modal)
  page.style.paddingRight = `${scrollbarWidth()}px`
  header.style.paddingRight = `${scrollbarWidth()}px`
  modal.classList.add('show')
  disableBodyScroll(modal)

  imageContainers.forEach(imageContainer => {
    if (imageContainer.classList.contains('show')) {
      const video = imageContainer.querySelector('video')
      if (video) {
        video.muted = true
        video.play()
      }
    }
  })
}

const onHideModal = modal => {
  page.style.paddingRight = ''
  header.style.paddingRight = ''

  modal.classList.remove('show')
  enableBodyScroll(modal)

  imageContainers.forEach(imageContainer => {
    const video = imageContainer.querySelector('video')
    if (video) {
      video.muted = true
      video.pause()
    }
  })
}

export default container => {
  const popup = container.querySelector('[data-gallery-popup]')

  const buttons = container.querySelectorAll('[data-gallery-popup-button]')

  let marzipanoDone = false

  if (!popup) return

  buttons.forEach(button => {
    button.addEventListener('click', e => {
      e.preventDefault()
      // the library wants to be given only an id of the modal
      MicroModal.show(popup.id, {
        onShow: modal => {
          onShowModal(modal)
          if (!marzipanoDone) {
            marzipanoDone = true
            initMarzipano(popup)
          }
        },
        onClose: modal => onHideModal(modal),
      })
    })
  })
}
