export default container => {
  const details = container.querySelectorAll('[data-details]')

  details.forEach(item => {
    const detailsButton = item.querySelector('[data-details-button]')
    if (detailsButton) {
      detailsButton.addEventListener('click', () => {
        item.classList.toggle('close')
      })
    }
  })
}
