const observer = new IntersectionObserver(
  entries => {
    entries.forEach(entry => {
      const { target } = entry

      if (entry.isIntersecting || entry.boundingClientRect.top < 0) {
        if (target.tagName === 'IMG') {
          target.src = target.dataset.src
          target.srcset = target.dataset.src2x || target.dataset.src

          target.addEventListener('load', () => {
            target.parentNode.classList.add('load')
          })
        } else {
          const fisrtMediaMatch = window.matchMedia(
            '(-webkit-min-device-pixel-ratio: 2)'
          ).matches
          const secondMediaMatch = window.matchMedia('(min-resolution: 192dpi)')
            .matches
          const thirdMediaMatch = window.matchMedia('(min-resolution: 2dppx)')
            .matches

          if (fisrtMediaMatch || secondMediaMatch || thirdMediaMatch) {
            target.style.backgroundImage =
              target.dataset.src2x || target.dataset.src
          } else {
            target.style.backgroundImage = target.dataset.src
          }
        }
        observer.unobserve(target)
      }
    })
  },
  {
    rootMargin: '500px 500px 500px 500px',
  }
)

export default container => {
  container
    .querySelectorAll('[data-lazy]')
    .forEach(image => observer.observe(image))
}
