import Accordion from 'accordion-js'
import '../styles/blocks/_accordion.scss'

export default container => {
  const accordions = container.querySelectorAll('[data-accordion]')
  const galleryAccordions = container.querySelectorAll(
    '[data-gallery-accordion]'
  )

  galleryAccordions.forEach(accordion => {
    new Accordion(accordion, {
      duration: 800,
      openOnInit: [0],
    })
  })

  accordions.forEach(accordion => {
    const menuButton = document.querySelector('[data-menu-button]')
    const menuLinks = document.querySelectorAll('[data-menu-link]')

    const acc = new Accordion(accordion, {
      showMultiple: false,
      duration: 800,
    })

    menuButton.addEventListener('click', () => {
      acc.closeAll()
    })

    menuLinks.forEach(menuLink => {
      menuLink.addEventListener('click', () => {
        acc.closeAll()
      })
    })
  })
}
