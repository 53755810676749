export const mapSelectorsToConfigs = {
  '[data-services-slider]': {
    preloadImages: false,
    watchSlidesVisibility: true,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2,
      loadOnTransitionStart: true,
    },
    speed: 1000,
    slidesPerView: 'auto',
    navigation: {
      nextEl: '[data-services-slide-next]',
      prevEl: '[data-services-slide-prev]',
    },
    pagination: {
      el: '[data-services-pagination]',
      type: 'bullets',
    },
  },

  '[data-half-slider]': {
    preloadImages: false,
    watchSlidesVisibility: true,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2,
      loadOnTransitionStart: true,
    },
    speed: 1000,
    slidesPerView: 'auto',
    watchOverflow: true,
    pagination: {
      el: '[data-half-pagination]',
      type: 'bullets',
    },
  },
  '[data-scale-slider-pagination]': {
    preloadImages: false,
    watchSlidesVisibility: true,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2,
      loadOnTransitionStart: true,
    },
    speed: 1000,
    slidesPerView: 'auto',
    watchOverflow: true,
    loop: true,
    navigation: {
      nextEl: '[data-slider-pagination-next]',
      prevEl: '[data-slider-pagination-prev]',
    },
    pagination: {
      el: '[data-scale-slider-pagination-el]',
      type: 'bullets',
    },
    breakpoints: {
      1023: {
        pagination: {
          el: '[data-scale-slider-pagination-el]',
          type: 'fraction',
        },
      },
    },
  },
  '[data-project-slider]': {
    preloadImages: false,
    watchSlidesVisibility: true,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2,
      loadOnTransitionStart: true,
    },
    speed: 1000,
    slidesPerView: 1,
    watchOverflow: true,
    navigation: {
      nextEl: '[data-project-slider-next]',
      prevEl: '[data-project-slider-prev]',
    },
    pagination: {
      el: '[data-project-pagination]',
      type: 'bullets',
    },
  },
}

export const restConfigs = {
  mobileOnly: {
    preloadImages: false,
    watchSlidesVisibility: true,
    slideToClickedSlide: true,
    speed: 1000,
    watchOverflow: true,
    slidesPerView: 'auto',
  },
  servicesMobileOnly: {
    preloadImages: false,
    watchSlidesVisibility: true,
    slideToClickedSlide: true,
    speed: 1000,
    watchOverflow: true,
    slidesPerView: 'auto',
    pagination: {
      el: '[data-mobile-services-pagination]',
      type: 'bullets',
    },
  },
  doubleFirst: {
    preloadImages: false,
    watchSlidesVisibility: true,
    autoplay: {
      delay: 7000,
    },
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2,
      loadOnTransitionStart: true,
    },
    speed: 1000,
    slidesPerView: 1,
    navigation: {
      nextEl: '[data-legend-double-slider-next]',
      prevEl: '[data-legend-double-slider-prev]',
    },
  },
  doubleSecond: {
    preloadImages: false,
    watchSlidesVisibility: true,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2,
      loadOnTransitionStart: true,
    },
    speed: 1000,
    slidesPerView: 1,
    pagination: {
      el: '[data-legend-double-slider-bullets]',
      type: 'bullets',
    },
  },
}
