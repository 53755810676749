import Swiper, { Navigation, Pagination, Lazy, Controller } from 'swiper'
import { mapSelectorsToConfigs, restConfigs } from './configs'
import 'swiper/swiper-bundle.css'
import '../../styles/blocks/_slider.scss'

const small = window.matchMedia('(max-width: 39.9375em)')
const smallAndUp = window.matchMedia('(max-width: 64em)')
const mediumAndUp = window.matchMedia('(min-width: 40em)')
const large = window.matchMedia('(min-width: 64em)')
Swiper.use([Navigation, Pagination, Lazy, Controller])

const initMobileSliders = page => {
  const mobileGallerySliders = page.querySelectorAll(
    '[data-mobile-gallery-slider]'
  )

  const mobileServicesSliders = page.querySelectorAll(
    '[data-mobile-services-slider]'
  )

  const sliderInit = (sliders, config, props) => {
    const { firstBreakpoint, secondBreakpoint } = props
    let mobileSwiper
    let init = false
    sliders.forEach(slider => {
      const mobileSlider = () => {
        if (firstBreakpoint.matches) {
          init = true
          mobileSwiper = new Swiper(slider, config)
        } else if (secondBreakpoint.matches && init) {
          mobileSwiper.destroy()
        }
      }

      if (slider) {
        window.addEventListener('resize', () => {
          mobileSlider()
        })

        window.addEventListener('load', () => {
          mobileSlider()
        })
      }
    })
  }

  sliderInit(mobileGallerySliders, restConfigs.mobileOnly, {
    firstBreakpoint: small,
    secondBreakpoint: mediumAndUp,
  })
  sliderInit(mobileServicesSliders, restConfigs.servicesMobileOnly, {
    firstBreakpoint: smallAndUp,
    secondBreakpoint: large,
  })
}

const initDoubleSliders = page => {
  const doubleSliderFirst = new Swiper(
    page.querySelector('[data-legend-double-slider-first]'),
    restConfigs.doubleFirst
  )

  const doubleSliderSecond = new Swiper(
    page.querySelector('[data-legend-double-slider-second]'),
    restConfigs.doubleSecond
  )

  doubleSliderSecond.controller.control = doubleSliderFirst
  doubleSliderFirst.controller.control = doubleSliderSecond
}

const swiperObserver = new IntersectionObserver(
  entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const firstMatchedSelector = Object.keys(
          mapSelectorsToConfigs
        ).filter(selector => entry.target.matches(selector))[0]
        if (firstMatchedSelector) {
          new Swiper(entry.target, mapSelectorsToConfigs[firstMatchedSelector])
        }
        swiperObserver.unobserve(entry.target)
      }
    })
  },
  { rootMargin: '0px 0px 0px' }
)

export default page => {
  initMobileSliders(page)
  initDoubleSliders(page)

  const selector = Object.keys(mapSelectorsToConfigs).join(', ')
  page.querySelectorAll(selector).forEach(el => swiperObserver.observe(el))
}
