import MicroModal from 'micromodal'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { header, page } from './_constants'
import scrollbarWidth from './scrollbar-width'

export default container => {
  const videoPlayButtons = container.querySelectorAll('[data-video-play]')
  const videoModals = container.querySelectorAll('[data-video-modal]')
  const ctaButton = container.querySelector('.cta-button')
  const buttonBack = container.querySelector('[data-cover-button-back]')

  const onShowModal = (modal, video) => {
    page.style.paddingRight = `${scrollbarWidth()}px`
    if (header) header.style.paddingRight = `${scrollbarWidth()}px`
    videoModals.forEach(videoModal => {
      videoModal.style.paddingRight = `${scrollbarWidth()}px`
      videoModal.style.paddingLeft = `${scrollbarWidth()}px`
    })
    if (header) header.classList.add('header--unpinned')
    page.classList.add('modal-show')
    if (buttonBack) {
      buttonBack.style.zIndex = '0'
    }

    disableBodyScroll(modal)
    video.play()
  }

  const onHideModal = (modal, video) => {
    page.style.paddingRight = ''
    if (header) header.style.paddingRight = ''
    videoModals.forEach(videoModal => {
      videoModal.style.paddingRight = ''
      videoModal.style.paddingLeft = ''
    })
    if (header) header.classList.remove('header--unpinned')
    page.classList.remove('modal-show')
    if (ctaButton) {
      ctaButton.style.transitionDelay = '0s'
    }
    if (buttonBack) {
      buttonBack.style.zIndex = '3'
    }

    enableBodyScroll(modal)
    video.pause()
  }

  videoPlayButtons.forEach(videoPlayButton => {
    videoPlayButton.addEventListener('click', () => {
      videoModals.forEach(videoModal => {
        const videos = videoModal.querySelectorAll('[data-video-content]')
        videos.forEach(video => {
          if (
            videoPlayButton.dataset.videoPlay === video.dataset.videoContent
          ) {
            MicroModal.show(videoModal.id, {
              onShow: modal => onShowModal(modal, video),
              onClose: modal => onHideModal(modal, video),
            })
          }
        })
      })
    })
  })
}
