const dropdown = (wrapper = document) => {
  const itemsContainer = wrapper.querySelectorAll('[data-dropdown-container]')
  const itemsList = wrapper.querySelectorAll('[data-dropdown-list]')

  itemsContainer.forEach(container => {
    container.addEventListener('click', () => {
      const currentList = container.querySelector('[data-dropdown-list]')
      currentList.classList.add('show')
    })

    window.addEventListener('keydown', evt => {
      if (evt.key === 'Escape') {
        itemsList.forEach(list => list.classList.remove('show'))
      }
    })

    document.addEventListener('click', e => {
      if (!e.target.dataset.dropdownActive) {
        itemsList.forEach(list => list.classList.remove('show'))
      }
    })
  })

  wrapper.querySelectorAll('[data-navigate-on-change]').forEach(select => {
    select.addEventListener('change', () => {
      window.location = select.value
    })
  })
}

export default dropdown
