const optionalFields = () => {
  const forms = document.querySelectorAll('[data-modal-form]')

  forms.forEach(form => {
    const btn = form.querySelector('[data-optional-field]')
    const secondForm = form.querySelector('[data-form-body-second]')

    if (!btn || !secondForm) return

    btn.addEventListener('click', () => {
      secondForm.classList.toggle('form-hidden')
    })
  })
}

export default optionalFields
