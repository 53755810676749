import { Loader } from 'google-maps'
import styles from '../data/map-style.json'

export default function initGoogleMap(page) {
  const map = page.querySelector('[data-google-map]')

  if (!map) {
    return
  }

  const { marker, lat, lng, key, zoom } = map.dataset
  const center = {
    lat: +lat,
    lng: +lng,
  }

  new Loader(key).load().then(google => {
    const googleMap = new google.maps.Map(map, {
      center,
      styles,
      zoom: +zoom,
      disableDefaultUI: true,
      zoomControl: true,
    })

    new google.maps.Marker({
      position: center,
      map: googleMap,
      icon: {
        url: marker,
        scaledSize: new google.maps.Size(32, 32),
        anchor: new google.maps.Point(32, 32),
      },
      animation: google.maps.Animation.DROP,
    })
  })
}
