import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const videoObserver = parentSelector => {
  gsap.config({ nullTargetWarn: false })

  const coverVideos = gsap.utils.toArray(`${parentSelector} [data-cover-video]`)

  coverVideos.forEach(coverVideo => {
    const videoSrcKey = window.innerWidth > 414 ? 'srcDesktop' : 'srcMobile'
    if (coverVideo.dataset[videoSrcKey]) {
      coverVideo.src = coverVideo.dataset[videoSrcKey]
    }
    gsap.to(coverVideo, {
      scrollTrigger: {
        start: 'top +=1',
        end: 'top -=80%',
        target: coverVideo,
        onEnter: () => coverVideo.play(),
        onLeave: () => coverVideo.pause(),
        onEnterBack: () => coverVideo.play(),
        onLeaveBack: () => coverVideo.pause(),
      },
    })
  })

  const previewVideos = gsap.utils.toArray(
    `${parentSelector} [data-preview-video]`
  )

  previewVideos.forEach(previewVideo => {
    gsap.to(previewVideo, {
      scrollTrigger: {
        start: 'top -=120%',
        end: 'top -=250%',
        target: '[data-about-section]',
        onEnter: () => previewVideo.play(),
        onLeave: () => previewVideo.pause(),
        onEnterBack: () => previewVideo.play(),
        onLeaveBack: () => previewVideo.pause(),
      },
    })
  })
}

export default videoObserver
