import axios from 'axios'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'

const ignoreFieldNames = ['nid', 'parent_id', 'photo_gallery']

const parsers = {
  default: data => {
    if (!Array.isArray(data)) return null

    if (data.length === 1 && data[0].value) {
      return data[0].value
    }

    if (data.length > 1) {
      return data.map(({ value }) => value).join(', ')
    }

    return null
  },
  imageestate: value => {
    if (Array.isArray(value) && value[0]) {
      return value.url
    }
    return null
  },
}

const prepareSingleApartment = line => {
  // recognize fields from Drupal
  const fieldNames = Object.keys(line)
    .map(key => key.match(/^field_(.*)/))
    .filter(Boolean)
    .map(matches => matches[1])
    .filter(key => !ignoreFieldNames.includes(key))

  // flatten data from Drupal
  const fields = {}

  fieldNames.forEach(field => {
    const parser = parsers[field] || parsers.default
    fields[field] = parser(line[`field_${field}`])
  })

  // parse id, type and house from "internaldescription field" and remove this field
  let id = null
  if (typeof fields.internaldescription === 'string') {
    const matches = fields.internaldescription.match(/[ABC][0-9]0[0-9]/)
    if (matches && matches[0]) {
      fields.id = matches[0]
      id = matches[0]
    }
    const matches2 = fields.internaldescription.match(/Typ ([A-Za-z]+)/)
    if (matches2 && matches2[1]) {
      fields.type = matches2[1]
    }
    const matches3 = fields.internaldescription.match(/Haus: ([A-Za-z]+)/)
    if (matches3 && matches3[1]) {
      fields.house = matches3[1]
    }
  }
  fields.internaldescription = null

  // prepare Drupal path
  let path = null
  if (line.path && line.path[0]) {
    const { alias, langcode } = line.path[0]
    if (alias && langcode) {
      path = `${window.burgenstockDrupalUrl}/${langcode}${alias}`
    }
  }

  return { fields, path, id }
}

const fetchApartmentsFromApi = urlToFetch =>
  axios.get(urlToFetch).then(response => {
    const apartments = response.data.map(prepareSingleApartment)
    const guaranteedFieldNames = ['id', 'house', 'type']
    const nonEmptyFieldsOfEachApartment = apartments.map(apartment =>
      Object.keys(apartment.fields).filter(
        key => !guaranteedFieldNames.includes(key) && apartment.fields[key]
      )
    )
    const nonEmptyFields = flatten(nonEmptyFieldsOfEachApartment)
    const uniqueNonEmptyFieldNames = guaranteedFieldNames.concat(
      uniq(nonEmptyFields)
    )
    return { apartments, uniqueNonEmptyFieldNames }
  })

export default fetchApartmentsFromApi
