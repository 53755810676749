export default container => {
  const modalButtons = container.querySelectorAll('[data-modal-form-button]')
  const modals = container.querySelectorAll('[data-modal-form]')
  let isOpen = false

  modalButtons.forEach(modalButton => {
    modalButton.addEventListener('click', () => {
      const activeModal = [...modals].find(
        modal => modal.dataset.modalForm === modalButton.dataset.modalFormButton
      )

      if (isOpen) {
        activeModal.classList.remove('show')
        modalButton.classList.remove('show')
        isOpen = false
      } else {
        activeModal.classList.add('show')
        modalButton.classList.add('show')
        isOpen = true
      }
    })
  })

  window.addEventListener('keydown', e => {
    if (e.key === 'Escape') {
      modals.forEach(modal => modal.classList.remove('show'))
      modalButtons.forEach(modalButton => modalButton.classList.remove('show'))
      isOpen = false
    }
  })
}
