/**
 * Entry point for webpack
 * Import styles, fonts, icons, scripts and other things that your project design requires here
 */

import objectFitImages from 'object-fit-images'
import svg4everybody from 'svg4everybody'
import MicroModal from 'micromodal'

import './styles/_fonts.scss'
import './styles/_reset.scss'
import './styles/main.scss'
import './styles/_form.scss'
import './styles/_cta.scss'
import './styles/_atomic.scss'
import './styles/_button.scss'
import './styles/_title.scss'
import './styles/_text.scss'
import './styles/blocks/_dropdown.scss'
import './styles/blocks/_header.scss'
import './styles/blocks/_menu.scss'
import './styles/blocks/_menu-is-active.scss'
import './styles/blocks/_cover.scss'
import './styles/blocks/_footer.scss'
import './styles/blocks/_section.scss'
// import './styles/blocks/_residence.scss'
import './styles/blocks/_gallery-section.scss'
import './styles/blocks/_resort.scss'
import './styles/blocks/_popup-slider.scss'
import './styles/blocks/_preview.scss'
import './styles/blocks/_modal-form.scss'
import './styles/blocks/_legend.scss'
import './styles/animations/_header.scss'
import './styles/animations/_cover.scss'
import './styles/animations/_about.scss'
import './styles/animations/_preview.scss'
import './styles/animations/_services.scss'
import './styles/animations/_history.scss'
import './styles/animations/_gallery-section.scss'
import './styles/animations/_resort-popup.scss'
import './styles/animations/_residence-modal.scss'
import './styles/blocks/_base-animations.scss'
import './styles/blocks/_menu-animations.scss'
import './styles/blocks/_restaurants.scss'
import './styles/blocks/_single-restaurant.scss'
import './styles/blocks/_two-half.scss'
import './styles/blocks/_two-half-base.scss'
import './styles/blocks/_apartments.scss'
import './styles/blocks/_layout.scss'
import './styles/blocks/_project.scss'
import './styles/blocks/_buildings.scss'
import './styles/blocks/_privacy.scss'
import './styles/blocks/_partners.scss'
import './styles/blocks/_contacts.scss'
import './styles/blocks/_gallery.scss'
import './styles/blocks/_info-section.scss'
import './styles/blocks/_preview-object.scss'
import './styles/rtl/rtl-main.scss'
import './styles/rtl/rtl-sliders.scss'
import './styles/rtl/rtl-two-half.scss'
import './styles/rtl/rtl-cards.scss'
import './styles/rtl/rtl-sections.scss'

import './scripts/menu'
import modal from './scripts/modal'
// import popup from './scripts/pop-up'
import form from './form'
import lazyLoad from './scripts/lazy-load'
import slider from './scripts/slider'
import accordion from './scripts/accordion'
import gsapAnimations from './scripts/gsap-animations'
import dropdown from './scripts/dropdown'
import headerObserver from './scripts/header-color'
import videoObserver from './scripts/video-observer'
import loadingAnimation from './scripts/loading-animation'
import videoModal from './scripts/video-play'
import staticCovers from './scripts/change-static-cover'
import previewObserver from './scripts/preview-section-observer'
import changeImageOnHover from './scripts/image-change'
import sliderButtonStatus from './scripts/slider-button-clicked-status'
import activateModalForm from './scripts/modal-form-activate'
import tabs from './scripts/two-half-tabs'
import fancy from './scripts/fancybox'
import hashNav from './scripts/hash-navigation'
import detailsToggle from './scripts/details-toggle'
import map from './scripts/map'
import shuffle from './scripts/shuffle'
import { header } from './scripts/_constants'
import vueBuildings from './scripts/vue-building'
import coverHeight from './scripts/cover-image-observer'
import aerialTabs from './scripts/aerial-tabs'
import optionalFields from './scripts/optional-fields-form'

// initialize things like header, footer
const oneTimeThings = () => {
  // svg sprites support in IE11
  svg4everybody()
  form(document.querySelector('[data-footer]'))
  accordion(document.querySelector('[data-one-time]'))
  modal(document)
  gsapAnimations('[data-one-time]')
  dropdown()
  headerObserver()
  changeImageOnHover()
  aerialTabs()
  optionalFields()

  const digitalExposePopup = document.getElementById('digital-expose-popup')
  if (digitalExposePopup) {
    form(digitalExposePopup)
  }

  document.querySelectorAll('[data-micromodal-close]').forEach(el => {
    el.addEventListener('click', () => {
      MicroModal.close(el.dataset.micromodalClose)
    })
  })
}

// initialize things inside of data-barba="container"
const onPageLoad = page => {
  // object-fit support in IE11
  objectFitImages()

  form(page)
  lazyLoad(page)
  slider(page)
  accordion(page)
  // popup(page)
  modal(page)
  gsapAnimations('[data-barba="container"]')
  dropdown(page)
  videoObserver('[data-barba="container"]')
  videoModal(page)
  staticCovers()
  previewObserver('[data-barba="container"]')
  sliderButtonStatus(page)
  activateModalForm(page)
  tabs(page)
  fancy(page)
  hashNav(page)
  detailsToggle(page)
  map(page)
  shuffle(page)
  vueBuildings()
  coverHeight(page)
  // MicroModal.init()
  page.querySelectorAll('[data-micromodal-trigger]').forEach(trigger => {
    trigger.addEventListener('click', () => {
      MicroModal.show(trigger.dataset.micromodalTrigger, {
        openClass: 'show',
      })
    })
  })
}

document.addEventListener('DOMContentLoaded', () => {
  oneTimeThings()

  const container = document.querySelector('[data-barba="container"]')
  loadingAnimation()
  onPageLoad(container)

  const headerColor = container.querySelector('[data-header-always-dark]')
  if (headerColor) {
    header.classList.add('header--full-dark')
  } else {
    header.classList.remove('header--full-dark')
  }
})
