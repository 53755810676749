import Headroom from 'headroom.js'
import { header } from './_constants'
import { headerOnTop } from './_states'

const headerObserver = () => {
  if (!header) return
  const headroom = new Headroom(header, {
    offset: 300,
    classes: {
      notTop: 'header--dark',
      unpinned: 'header--unpinned',
      pinned: 'header--pinned',
    },
    onTop: () => {
      headerOnTop.top = true
    },
    onNotTop: () => {
      headerOnTop.top = false
    },
    onUnpin: () => {
      document
        .querySelectorAll('[data-dropdown-list]')
        .forEach(el => el.classList.remove('show'))
    },
  })
  headroom.init()
}

export default headerObserver
