import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'
import '../styles/fancybox.scss'

const galleryInit = () => {
  Fancybox.bind('[data-fancybox]', {
    closeButton: 'top',
    template: {
      closeButton:
        '<svg width="19" height="19" viewBox="0 0 19 19" fill="#2B2B2B" xmlns="http://www.w3.org/2000/svg"><rect x="1.06055" width="24" height="1.5" transform="rotate(45 1.06055 0)" /><rect x="0.0605469" y="16.9707" width="24" height="1.5" transform="rotate(-45 0.0605469 16.9707)" /></svg>',
    },
    Thumbs: false,
    Carousel: {
      Dots: true,
    },
    Image: {
      zoom: false,
      wheel: false,
      click: false,
    },

    on: {
      initLayout: el => {
        const title = document.createElement('h3')
        title.classList.add('fancybox__title')
        const titleText = document.querySelector('[data-fancy-title]')
        if (titleText) {
          title.textContent = titleText.dataset.fancyTitle
          el.$backdrop.after(title)
        }
      },
    },
  })
}

export default galleryInit
