import Marzipano from 'marzipano'

import './css/lib/hint.css'
import './css/expand.css'
import './css/hintspot.css'
import './css/info.css'
import './css/reveal.css'
import './css/rotate.css'
import './css/textInfo.css'
import './css/tooltip.css'

const sanitizedParseFloat = string => {
  const num = Number(string)
  return Number.isNaN(num) ? 0 : string
}

const initMarzipano = page =>
  page.querySelectorAll('[data-marzipano-container]').forEach(container => {
    const el = container.querySelector('[data-marzipano-viewer]')
    const viewer = new Marzipano.Viewer(el)

    const { imageRootUrl } = el.dataset
    const source = Marzipano.ImageUrlSource.fromString(
      `${imageRootUrl}/{z}/{f}/{y}/{x}.jpg`,
      { cubeMapPreviewUrl: `${imageRootUrl}/preview.jpg` }
    )

    const geometry = new Marzipano.CubeGeometry([
      { tileSize: 256, size: 256, fallbackOnly: true },
      { size: 512, tileSize: 512 },
      { size: 1024, tileSize: 512 },
      { size: 2048, tileSize: 512 },
    ])

    const limiter = Marzipano.RectilinearView.limit.traditional(
      2048,
      (120 * Math.PI) / 180
    )
    const view = new Marzipano.RectilinearView(null, limiter)

    const scene = viewer.createScene({
      source,
      geometry,
      view,
      pinFirstLevel: true,
    })

    scene.switchTo({ transitionDuration: 0 })

    // tooltips
    container
      .querySelectorAll('[data-marzipano-hotspot]')
      .forEach(tooltipEl => {
        const { yaw, pitch, roll } = tooltipEl.dataset
        const config = {}
        config.yaw = sanitizedParseFloat(yaw)
        config.pitch = sanitizedParseFloat(pitch)
        config.roll = sanitizedParseFloat(roll)
        scene.hotspotContainer().createHotspot(tooltipEl, config)
      })
  })

export default initMarzipano
