import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin'
import { enableBodyScroll } from 'body-scroll-lock'
import { header, page, mobile } from './_constants'

gsap.registerPlugin(ScrollTrigger, SplitText, MorphSVGPlugin)

const animations = parentSelector => {
  gsap.config({ nullTargetWarn: false })
  const covers = gsap.utils.toArray(`${parentSelector} [data-static-cover]`)

  // about section animations
  const aboutSectionAnimation = () => {
    const aboutSections = gsap.utils.toArray(
      `${parentSelector} [data-about-section]`
    )

    aboutSections.forEach(aboutSection => {
      const linesP = aboutSection.querySelectorAll('[data-lines-split] p')
      const revelElements = aboutSection.querySelectorAll('[data-show-reveal]')
      const revelImgElements = aboutSection.querySelectorAll(
        '[data-img-reveal]'
      )

      const splitLinesP = new SplitText(linesP, {
        type: 'lines',
        linesClass: 'line-child',
      })

      new SplitText(linesP, {
        type: 'lines',
        linesClass: 'line-parent',
      })

      revelElements.forEach(revelElement => {
        gsap.to(revelElement, {
          scrollTrigger: {
            trigger: aboutSection,
            start: 'top 30%',
            onEnter: () => revelElement.classList.add('show'),
          },
        })
      })

      revelImgElements.forEach(revelElement => {
        gsap.to(revelElement, {
          scrollTrigger: {
            trigger: aboutSection,
            start: 'top 90%',
            onEnter: () => revelElement.classList.add('show'),
          },
        })
      })

      splitLinesP.lines.forEach(el => {
        gsap.to(el, {
          scrollTrigger: {
            trigger: aboutSection,
            start: 'top 30%',
            onEnter: e => e.trigger.classList.add('lines-show'),
          },
        })
      })
    })
  }

  // services section animation

  const servicesSectionAnimation = () => {
    const servicesSections = gsap.utils.toArray(
      `${parentSelector} [data-services-section]`
    )

    servicesSections.forEach(servicesSection => {
      const linesP = servicesSection.querySelectorAll('[data-lines-split] p')
      const linesH2 = servicesSection.querySelectorAll('[data-lines-split] h2')
      const splitLinesH2 = new SplitText(linesH2, {
        type: 'lines, words',
        linesClass: 'line-parent',
        wordsClass: 'word-child word-child--++',
      })

      splitLinesH2.lines.forEach(el => {
        gsap.to(el, {
          scrollTrigger: {
            trigger: servicesSection,
            start: 'top 80%',
            onEnter: e => e.trigger.classList.add('words-show'),
          },
        })
      })

      const splitLinesP = new SplitText(linesP, {
        type: 'lines',
        linesClass: 'line-child',
      })

      new SplitText(linesP, {
        type: 'lines',
        linesClass: 'line-parent',
      })

      splitLinesP.lines.forEach(el => {
        gsap.to(el, {
          scrollTrigger: {
            trigger: servicesSection,
            start: 'top 40%',
            onEnter: e => e.trigger.classList.add('lines-show'),
          },
        })
      })
    })
  }

  // history section animation

  const historySectionAnimation = () => {
    const historySections = gsap.utils.toArray(
      `${parentSelector} [data-history-section]`
    )

    historySections.forEach(historySection => {
      const revelImgElements = historySection.querySelectorAll(
        '[data-img-reveal]'
      )
      const linesP = historySection.querySelectorAll('[data-lines-split] p')
      const linesH3 = historySection.querySelectorAll('[data-lines-split] h3')
      const wordsP = historySection.querySelectorAll('[data-words-split] p')
      const mainLinesP = historySection.querySelectorAll(
        '[data-main-lines-split] p'
      )
      const revelElements = historySection.querySelectorAll(
        '[data-show-reveal]'
      )

      revelElements.forEach(revelElement => {
        gsap.to(revelElement, {
          scrollTrigger: {
            trigger: historySection,
            start: 'bottom 70%',
            onEnter: () => revelElement.classList.add('show'),
          },
        })
      })

      const splitLinesP = new SplitText(linesP, {
        type: 'lines',
        linesClass: 'line-child line-child--++',
      })

      new SplitText(linesP, {
        type: 'lines',
        linesClass: 'line-parent',
      })

      const splitMainLinesP = new SplitText(mainLinesP, {
        type: 'lines',
        linesClass: 'line-child',
      })

      new SplitText(mainLinesP, {
        type: 'lines',
        linesClass: 'line-parent',
      })

      const splitWordsH3 = new SplitText(linesH3, {
        type: 'lines, words',
        linesClass: 'line-parent',
        wordsClass: 'single-word',
      })

      const splitWordsP = new SplitText(wordsP, {
        type: 'lines, words',
        linesClass: 'line-parent',
        wordsClass: 'word-child word-child--++',
      })

      splitMainLinesP.lines.forEach(el => {
        gsap.to(el, {
          scrollTrigger: {
            trigger: historySection,
            start: 'top 30%',
            onEnter: e => e.trigger.classList.add('main-lines-show'),
          },
        })
      })

      splitWordsH3.lines.forEach(el => {
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
            start: 'top 20%',
            onEnter: e => e.trigger.classList.add('single-word-show'),
          },
        })
      })

      splitWordsP.lines.forEach(el => {
        gsap.to(el, {
          scrollTrigger: {
            trigger: historySection,
            start: 'top 40%',
            onEnter: e => e.trigger.classList.add('words-show'),
          },
        })
      })

      splitLinesP.lines.forEach(el => {
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
            start: '+=200 bottom',
            onEnter: e => e.trigger.classList.add('lines-show'),
          },
        })
      })

      revelImgElements.forEach(revelElement => {
        gsap.to(revelElement, {
          scrollTrigger: {
            trigger: historySection,
            start: 'top 90%',
            onEnter: () => revelElement.classList.add('show'),
          },
        })
      })
    })
  }

  // gallery-section animations

  const gallerySectionAnimation = () => {
    const gallerySections = gsap.utils.toArray(
      `${parentSelector} [data-gallery-section]`
    )

    gallerySections.forEach(gallerySection => {
      const linesH2 = gallerySection.querySelectorAll('[data-lines-split] h2')
      const revelElements = gallerySection.querySelectorAll(
        '[data-show-reveal]'
      )

      revelElements.forEach(revelElement => {
        gsap.to(revelElement, {
          scrollTrigger: {
            trigger: gallerySection,
            start: 'top 30%',
            onEnter: () => revelElement.classList.add('show'),
          },
        })
      })

      const splitLinesH2 = new SplitText(linesH2, {
        type: 'lines',
        linesClass: 'line-child line-child--++',
      })

      new SplitText(linesH2, {
        type: 'lines',
        linesClass: 'line-parent',
      })

      splitLinesH2.lines.forEach(el => {
        gsap.to(el, {
          scrollTrigger: {
            trigger: gallerySection,
            start: 'top 70%',
            onEnter: e => e.trigger.classList.add('lines-show'),
          },
        })
      })
    })
  }

  const coverSectionAnimation = () => {
    const coverSections = gsap.utils.toArray(
      `${parentSelector} [data-cover-section]`
    )

    coverSections.forEach(coverSection => {
      const coverButton = coverSection.querySelector('[data-button-svg]')
      const wordsH1 = coverSection.querySelectorAll('[data-cover-split] h1')
      const linesP = coverSection.querySelectorAll('[data-cover-words-split] p')
      const preloader = document.querySelector('[data-preloader]')

      new SplitText(wordsH1, {
        type: 'lines, words',
        linesClass: 'line-parent',
        wordsClass: 'word-child word-child--++',
      })

      new SplitText(linesP, {
        type: 'lines',
        linesClass: 'line-child line-child--++',
      })

      new SplitText(linesP, {
        type: 'lines',
        linesClass: 'line-parent',
      })

      if (coverButton) {
        gsap.set(coverButton, { opacity: 0 })
      }

      const button = document.querySelector('[data-button-svg]')

      const animationProps = {
        repeat: -1,
        yoyo: true,
        paused: true,
        duration: 2,
      }

      const firstCircle = gsap.to('#simple-circle-1', {
        morphSVG: '#morph-circle-1',
        duration: 0.4,
        delay: 0,
      })
      const secondCircle = gsap.to('#simple-circle-2', {
        morphSVG: '#morph-circle-2',
        duration: 0.4,
        delay: 0,
      })
      const thirdCircle = gsap.to('#simple-circle-3', {
        morphSVG: '#morph-circle-3',
        duration: 0.4,
        delay: 0,
      })

      if (coverButton) {
        coverButton.style.pointerEvents = 'none'

        const onCompleteSVGAnimation = () => {
          firstCircle.reverse()
          secondCircle.reverse()
          thirdCircle
            .reverse()
            .then(() => (coverButton.style.pointerEvents = 'all'))

          const firstAction = gsap.to('#simple-circle-1', {
            morphSVG: '#morph-circle-1',
            ...animationProps,
          })
          const secondAction = gsap.to('#simple-circle-2', {
            morphSVG: '#morph-circle-2',
            ...animationProps,
          })
          const thirdAction = gsap.to('#simple-circle-3', {
            morphSVG: '#morph-circle-3',
            ...animationProps,
          })

          button.addEventListener('mouseover', () => {
            firstAction.play()
            secondAction.play()
            thirdAction.play()
          })

          button.addEventListener('mouseleave', () => {
            firstAction.iteration(1)
            secondAction.iteration(1)
            thirdAction.iteration(1)
            firstAction.reverse()
            secondAction.reverse()
            thirdAction.reverse()
          })
        }

        gsap.fromTo(
          coverButton,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 0.8,
            delay: 5,
            onComplete: onCompleteSVGAnimation,
          }
        )
      }

      preloader.addEventListener('transitionend', () => {
        coverSection.classList.add('loaded')
        enableBodyScroll(preloader)
        if (header) header.style.paddingRight = 0
        page.style.paddingRight = 0
        covers.forEach(cover => (cover.style.paddingRight = 0))
      })
    })
  }

  const anim = () => {
    coverSectionAnimation()
    aboutSectionAnimation()
    servicesSectionAnimation()
    historySectionAnimation()
    gallerySectionAnimation()
  }

  if (parentSelector !== '[data-one-time]') {
    // document.fonts.ready.then(() => anim())
    anim()
  }

  // one time animations reveal

  if (parentSelector === '[data-one-time]') {
    const triggerElements = gsap.utils.toArray(
      `${parentSelector} [data-show-reveal]`
    )

    triggerElements.forEach(triggerElement => {
      gsap.to(triggerElement, {
        scrollTrigger: {
          trigger: triggerElement,
          onEnter: e => e.trigger.classList.add('show'),
        },
      })
    })
  }

  // all elements that are animated with css

  const singleLines = gsap.utils.toArray('[data-single-line-animation]')

  singleLines.forEach(singleLine => {
    gsap.to(singleLine, {
      scrollTrigger: {
        trigger: singleLine,
        start: '+=100 bottom',
        onEnter: e => e.trigger.classList.add('show'),
      },
    })
  })

  const cssAnimations = gsap.utils.toArray('[data-css-animation]')

  cssAnimations.forEach(cssAnimation => {
    gsap.to(cssAnimation, {
      scrollTrigger: {
        trigger: cssAnimation,
        onEnter: e => e.trigger.classList.add('show'),
      },
    })
  })

  // cover video, image

  gsap.set(`${parentSelector} [data-cover-animation]`, { scale: 1.2 })

  gsap.fromTo(
    `${parentSelector} [data-cover-animation]`,
    { scale: 1.2 },
    { scale: 1, delay: 1.7, duration: 1 }
  )

  // menu button show animation

  gsap.set(`${parentSelector} [data-menu-button-line]`, {
    scaleX: 0,
    transformOrigin: 'left',
  })

  ScrollTrigger.batch(`${parentSelector} [data-menu-button-line]`, {
    once: true,
    onEnter: batch =>
      gsap.fromTo(
        batch,
        {
          scaleX: 0,
        },
        {
          duration: 0.4,
          scaleX: 1,
          stagger: 0.1,
          delay: 2.5,
          clearProps: 'all',
        }
      ),
  })

  // parallax bg

  if (!mobile) {
    const imagesScale = gsap.utils.toArray(
      `${parentSelector} [data-image-scale]`
    )

    imagesScale.forEach(imageScale => {
      gsap.to(imageScale, {
        scrollTrigger: {
          trigger: imageScale,
          scrub: true,
          end: 'bottom 10%',
        },
        scale: 1,
      })
    })
  }

  // photos
  gsap.set(`${parentSelector} [data-photo-animate]`, { y: 100, opacity: 1 })

  const simpleImages = gsap.utils.toArray(
    `${parentSelector} [data-photo-animate]`
  )

  simpleImages.forEach(img => {
    gsap.fromTo(
      img,
      { y: 100, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.8,
        scrollTrigger: { trigger: img, start: '+=200 bottom' },
      }
    )
  })

  // animate cards

  gsap.set(`${parentSelector} [data-gallery-card]`, { opacity: 0, y: '80%' })

  ScrollTrigger.batch(`${parentSelector} [data-gallery-card]`, {
    once: true,
    onEnter: batch =>
      gsap.fromTo(
        batch,
        {
          opacity: 0,
          y: '80%',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.1,
          clearProps: 'all',
        }
      ),
  })

  gsap.set(`${parentSelector} [data-slider-image]`, { opacity: 0, y: '10%' })

  ScrollTrigger.batch(`${parentSelector} [data-slider-image]`, {
    once: true,
    start: 'top 70%',
    onEnter: batch =>
      gsap.fromTo(
        batch,
        {
          opacity: 0,
          y: '10%',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          delay: 0.15,
          stagger: 0.15,
          clearProps: 'all',
        }
      ),
  })

  gsap.set(`${parentSelector} [data-slider-text]`, { y: '100%' })

  ScrollTrigger.batch(`${parentSelector} [data-slider-text]`, {
    once: true,
    onEnter: batch =>
      gsap.fromTo(
        batch,
        {
          y: '100%',
        },
        {
          y: 0,
          duration: 0.8,
          stagger: 0.2,
          delay: 0.2,
          clearProps: 'all',
        }
      ),
  })

  // single pages

  gsap.set(`${parentSelector} [data-tablet-animation-item]`, {
    opacity: 0,
    y: '30%',
  })

  ScrollTrigger.batch(`${parentSelector} [data-tablet-animation-item]`, {
    once: true,
    start: 'top +=80%',
    onEnter: batch =>
      gsap.fromTo(
        batch,
        {
          opacity: 0,
          y: '30%',
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.1,
          clearProps: 'all',
        }
      ),
  })

  // disabling - not working in Chrome, texts just disappear

  // const opacityTexts = gsap.utils.toArray(
  //   `${parentSelector} [data-opacity-content]`
  // )

  // opacityTexts.forEach(opacityText => {
  //   gsap.fromTo(
  //     opacityText,
  //     { opacity: 0 },
  //     {
  //       opacity: 1,
  //       duration: 1.4,
  //       scrollTrigger: { trigger: opacityText },
  //       clearProps: 'all',
  //     }
  //   )
  // })

  const linesH2 = gsap.utils.toArray('[data-title-lines-split] h2')
  const linesH3 = gsap.utils.toArray('[data-title-lines-split] h3')

  const splitTextOnLines = text => {
    const splitLines = new SplitText(text, {
      type: 'lines',
      linesClass: 'line-child line-child--++',
    })

    new SplitText(text, {
      type: 'lines',
      linesClass: 'line-parent',
    })

    splitLines.lines.forEach(el => {
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
          start: 'top +=80%',
          onEnter: () => el.classList.add('title-lines-show'),
        },
      })
    })
  }

  splitTextOnLines(linesH2)
  splitTextOnLines(linesH3)

  const animationProps = {
    repeat: -1,
    yoyo: true,
    paused: true,
    duration: 2,
  }

  const buttons = gsap.utils.toArray(`${parentSelector} [data-svg-button]`)

  buttons.forEach(btn => {
    const firstAction = gsap.to('#simple-circle-11', {
      morphSVG: '#morph-circle-11',
      ...animationProps,
    })
    const secondAction = gsap.to('#simple-circle-22', {
      morphSVG: '#morph-circle-22',
      ...animationProps,
    })
    const thirdAction = gsap.to('#simple-circle-33', {
      morphSVG: '#morph-circle-33',
      ...animationProps,
    })

    btn.addEventListener('mouseover', () => {
      firstAction.play()
      secondAction.play()
      thirdAction.play()
    })

    btn.addEventListener('mouseleave', () => {
      firstAction.iteration(1)
      secondAction.iteration(1)
      thirdAction.iteration(1)
      firstAction.reverse()
      secondAction.reverse()
      thirdAction.reverse()
    })
  })
}

export default animations
