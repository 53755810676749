import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import scrollbarWidth from './scrollbar-width'
import { header, page } from './_constants'
import { headerOnTop } from './_states'

const menuButton = document.querySelector('[data-menu-button]')
const menu = document.querySelector('[data-menu]')
const menuLinks = document.querySelectorAll('[data-menu-link]')

const menuIsActive = () => page.classList.contains('menu-is-active')
const scrollbar = scrollbarWidth()

const toggleScrollbar = () => {
  if (menuIsActive()) {
    disableBodyScroll(menu)
  } else {
    enableBodyScroll(menu)
  }
  page.style.paddingRight = menuIsActive() ? `${scrollbar}px` : ''
  header.style.paddingRight = menuIsActive() ? `${scrollbar}px` : ''
  if (menuIsActive()) {
    setTimeout(() => {
      header.classList.add('header--dark')
    }, 100)
  } else {
    setTimeout(() => {
      if (!headerOnTop.top) {
        header.classList.remove('header--dark')
      }
    }, 1140)
  }
}

const toggleMenu = () => {
  page.classList.toggle('menu-is-active')
  menuButton.classList.toggle('is-active')
  menu.classList.toggle('is-active')
  toggleScrollbar()
}

if (menuButton) {
  menuButton.addEventListener('click', () => {
    toggleMenu()
  })
}

menuLinks.forEach(link => {
  link.addEventListener('click', () => {
    toggleMenu()
  })
})
