import Shuffle from 'shufflejs'
import imagesLoaded from 'imagesloaded'

const myShuffle = el => {
  const sizer = el.querySelector('[data-masonry-sizer]')
  if (!sizer) return

  const imgLoad = imagesLoaded(el)
  imgLoad.on('always', () => {
    new Shuffle(el, {
      itemSelector: '[data-masonry-item]',
      sizer,
    })
  })
}

export default container => {
  const masonryGridPhotos = container.querySelectorAll('[data-masonry-photos]')

  masonryGridPhotos.forEach(masonryGridPhoto => {
    myShuffle(masonryGridPhoto)
  })
}
