import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const initButtonObserver = (wrapper, cursor) => {
  wrapper.addEventListener('pointerdown', () => {
    cursor.classList.add('clicked')

    wrapper.addEventListener('pointerup', () => {
      cursor.classList.remove('clicked')
    })
  })
}

export default container => {
  const galleryButton = container.querySelector('[data-gallery-button]')
  const gallery = container.querySelector('[data-gallery-slider-container]')
  if (!galleryButton) return
  initButtonObserver(gallery, galleryButton)
}
