// import Vue from 'vue/dist/vue.esm'
import { Vue } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'

import fetchApartmentsFromApi from './lib/fetch-apartments-from-api'

const numericFieldNames = [
  'areabwf',
  'total',
  'areasianf',
  'price',
  'numberofffloors',
]

const vueBuildings = () => {
  new Vue({
    el: document.getElementById('vue-root'),
    components: {
      AgGridVue,
    },
    data() {
      return {
        apartments: [],
        activeApartment: null,
        fieldNames: [],
      }
    },

    mounted() {
      document.querySelectorAll('path[data-lot]').forEach(path => {
        path.addEventListener('click', () => {
          const apartment = this.apartments.find(
            line => line.id === path.dataset.lot
          )
          if (apartment) {
            this.activeApartment = apartment
          }
        })
      })
      document.body.addEventListener('click', this.onClick)
      if (window.burgenstockEstatesApiUrl) {
        fetchApartmentsFromApi(window.burgenstockEstatesApiUrl).then(
          ({ apartments, uniqueNonEmptyFieldNames }) => {
            this.apartments = apartments
            this.fieldNames = uniqueNonEmptyFieldNames
          }
        )
      }
    },
    beforeUnmount() {
      document.body.removeEventListener('click', this.onClick)
    },
    methods: {
      onClick(e) {
        if (!e.target.closest('path[data-lot]')) {
          this.closePreview()
        }
      },
      closePreview() {
        this.activeApartment = null
      },
      apartmentIsActive(apartment) {
        return this.activeApartment && this.activeApartment.id === apartment.id
      },
      showMore() {
        this.linesToShow += this.linesPerPage
      },
    },
    computed: {
      activeApartmentFieldNames() {
        return this.fieldNames.filter(
          field =>
            this.activeApartment &&
            this.activeApartment.fields[field] &&
            field !== 'id'
        )
      },
      // adjust data format to props required by ag-grid
      columnDefs() {
        return this.fieldNames.map(field => ({
          field,
          filter: numericFieldNames.includes(field)
            ? 'agNumberColumnFilter'
            : 'agTextColumnFilter',
        }))
      },
      rowData() {
        return this.apartments.map(({ fields }) => fields)
      },
    },
  })
}

export default vueBuildings
