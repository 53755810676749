import { mobile as isMobile } from './_constants'

export default container => {
  const covers = container.querySelectorAll('[data-cover]')

  const setContentHeight = (img, video) => {
    const height = window.innerHeight
    if (img && !('skip' in img.dataset)) img.style.height = `${height}px`
    if (video) video.style.height = `${height}px`
  }

  covers.forEach(cover => {
    const image = cover.querySelector('img')
    const video = cover.querySelector('video')

    if (isMobile) {
      setContentHeight(image, video)

      window.addEventListener('resize', () => {
        setContentHeight(image, video)
      })
    }
  })
}
