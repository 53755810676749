import { disableBodyScroll } from 'body-scroll-lock'
import { header, page } from './_constants'
import scrollbarWidth from './scrollbar-width'

const wrapper = document.querySelector('[data-preloader]')
const covers = document.querySelectorAll('[data-static-cover]')

const loadingAnimation = () => {
  disableBodyScroll(wrapper)
  if (header) header.style.paddingRight = `${scrollbarWidth()}px`
  page.style.paddingRight = `${scrollbarWidth()}px`
  wrapper.classList.add('loaded')
  page.classList.add('loaded')
  covers.forEach(cover => (cover.style.paddingRight = `${scrollbarWidth()}px`))
}

export default loadingAnimation
