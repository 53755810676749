import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const animations = parentSelector => {
  gsap.config({ nullTargetWarn: false })

  const aboutSections = gsap.utils.toArray(
    `${parentSelector} [data-about-section]`
  )

  const preview = gsap.utils.toArray(`${parentSelector} [data-preview-section]`)

  aboutSections.forEach(section => {
    gsap.to(section, {
      scrollTrigger: {
        once: true,
        trigger: section,
        end: '+=600 top',
        onLeave: () => preview[0].classList.add('show'),
      },
    })
  })
}

export default animations
