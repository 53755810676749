/**
 * EWM standard input customization
 *
 * Features:
 * - customized dropdown (native on Android/IOS for better UX)
 * - textarea resizes automatically as you type
 * - labels animation and underline animation on input / focus that work good on all input types
 * - customized file input
 * - optionally, you can enable customized datepicker (native on Android/IOS for better UX)
 * - ~6 KB gzipped without datepickers, ~20KB with datepickers - small CPU and network usage
 *
 * It leaves things like data flow out of scope.
 * Write separate scripts if you want to change something on page when certain input has been changed, or use React/Vue.
 *
 * Usage (HTML):
 *
 * <div class='input-block'>
 *  <label class='input-label' for='name'>Name</label>
 *  <input class='input-field' id='name' />
 * </div>
 * <div class='input-block'>
 *  <label class='input-label' for='gender'>Gender</label>
 *  <select class='input-field' id='gender' required>
 *    <option selected disabled></option>
 *    <option>Male</option>
 *    <option>Female</option>
 *  </select>
 * </div>
 * <div class='input-block'>
 *  <label class='input-label' for='birth'>Date of birth</label>
 *  <input class='input-field' id='birth' type='date' />
 * </div>
 * <div class='input-block'>
 *  <label class='input-label' for='bio'>Bio</label>
 *  <textarea class='input-field' id='bio'></textarea>
 * </div>
 *
 * Usage (JS):
 *
 * document.addEventListener('DOMContentLoaded', () => {
 *   customInputs(document.body);
 * })
 */

import Dropkick from 'dropkickjs'
import fitTextarea from 'fit-textarea'
import isMobile from 'ismobilejs'

// comment out if you don't need date inputs
import { desktopDatepicker, mobileDatepicker } from './custom-date-input'

const customInputs = container => {
  const useNativeInputs = isMobile().any

  const textareas = []

  const nodelist = container.querySelectorAll('[data-autosize-textarea]')
  if (nodelist) {
    nodelist.forEach(el => textareas.push(el))
    fitTextarea.watch(textareas)
  }

  container.querySelectorAll('.input-block').forEach(inputBlock => {
    const input = inputBlock.querySelector('.input-field')

    if (!input) return

    let collapseLabel = false

    const toggleCollapse = collapse => {
      if (input.type === 'date' && useNativeInputs) return
      if (collapseLabel === collapse) return
      if (!collapse && input.value !== '') return
      if (!collapse && input.type === 'file' && input.files.length !== '')
        return
      collapseLabel = collapse
      inputBlock.classList.toggle('input-block_collapsed')
    }

    const toggleFocus = focus => {
      inputBlock.classList.toggle('input-block_focused', focus)
      toggleCollapse(focus)
    }

    const revalidate = () => {
      const event = document.createEvent('Event')
      event.initEvent('input', true, true)
      input.dispatchEvent(event)
    }

    // comment out if you don't need date inputs

    if (input.getAttribute('type') === 'date') {
      if (useNativeInputs) {
        mobileDatepicker(input)
        collapseLabel = true
        inputBlock.classList.add('input-block_collapsed')
      } else {
        desktopDatepicker({ toggleFocus, input })
      }
    }

    if (input.value.trim() !== '') {
      toggleCollapse(true)
    }

    input.addEventListener('focusin', toggleFocus.bind(null, true))
    input.addEventListener('focusout', toggleFocus.bind(null, false))
    input.addEventListener('input', () => {
      toggleCollapse(input.value.trim() !== '')
    })
    input.form.addEventListener('reset', () => {
      // wait until inputs get cleaned
      setTimeout(() => toggleCollapse(false), 0)
    })

    if (input.tagName === 'SELECT' && !useNativeInputs) {
      const dk = new Dropkick(input, {
        mobile: true,
        close: toggleFocus.bind(null, false),
        change: revalidate,
      })
      input.addEventListener('focusin', () => dk.open())
      input.addEventListener('focusout', () => dk.close())
      const focusable = inputBlock.querySelector('[tabindex="0"]')
      if (focusable) {
        focusable.addEventListener('focusin', toggleFocus.bind(null, true))
        focusable.addEventListener('focusout', toggleFocus.bind(null, false))
      }
      const field = inputBlock.querySelector('.dk-select.input-field')
      if (field) {
        field.classList.remove('input-field')
      }
    }

    if (input.getAttribute('type') === 'file') {
      const filenameDisplay = inputBlock.querySelector(
        '[data-filename-display]'
      )
      input.addEventListener('change', e => {
        const { files } = e.target
        if (files.length !== 0) {
          const t = []
          for (let i = 0; i < files.length; i++) {
            t.push(files[i].name)
          }
          filenameDisplay.value = t.join(', ')
        }
      })
    }
  })
}

export default customInputs
