/* eslint-disable */

/**
 * Add invalid class on input wrapper element based on HTML5 validation of child input.
 * No NPM packages! Because browsers can do that!
 *
 * Learn more:
 * https://pageclip.co/blog/2018-02-20-you-should-use-html5-form-validation.html
 * https://github.com/Pageclip/valid-form
 *
 *
 * Usage:
 *
 * <style>
 *   .invalid { color: red }
 *   .error { visibility: hidden }
 *   .invalid .error { visibility: visible }
 * </style>
 *
 * <form action="/submit" data-focus-first-invalid>
 *    <label data-validateable-block>
 *      <span>Name:</span> <input data-validateable-input required />
 *      <div class="error">Input is required</div>
 *    </label>
 *    <button>Submit</button>
 * </form>
 *
 *
 * In JS:
 *
 * import validate from './validate'
 *
 * document.addEventListener('DOMContentLoaded', () => {
 *   validate(document.body);
 * });
 */

const INVALID_CLASS = 'invalid'
const changeEvents = ['input', 'change']

function debounce(fn, ms) {
  let timeout
  return () => {
    clearTimeout(timeout)
    timeout = setTimeout(fn, ms)
  }
}

export default function(container) {
  container.querySelectorAll('[data-validateable-block]').forEach(block => {
    block.querySelectorAll('[data-validateable-input]').forEach(input => {
      // fix for flatpickr
      if (input._flatpickr && input._flatpickr.altInput) {
        input = input._flatpickr.altInput
      }

      input.addEventListener('invalid', e => {
        e.preventDefault()
        block.classList.add(INVALID_CLASS)
        if(input.dataset.validateableHidden !== undefined) {
          let test = container.querySelector('[data-validateable-hidden-error]')
          test.classList.add(INVALID_CLASS)
        }
      })

      changeEvents.forEach(evtName => {
        input.addEventListener(evtName, () => {
          if (input.validity.valid) {
            block.classList.remove(INVALID_CLASS)
            if(input.dataset.validateableHidden !== undefined) {
              let test = container.querySelector('[data-validateable-hidden-error]')
              test.classList.remove(INVALID_CLASS)
            }
          }
        })
      })
    })
  })

  // focus first invalid field
  container.querySelectorAll('[data-focus-first-invalid]').forEach(el => {
    const focusFirst = debounce(() => {
      const invalidNode = el.querySelector('[data-validateable-input]:invalid')
      if (invalidNode) invalidNode.focus()
    }, 100)
    el.querySelectorAll('[data-validateable-input]').forEach(input =>
      input.addEventListener('invalid', focusFirst)
    )
  })
}
